<template>
  <section>
    <div class="content-header">
      <h2>Online resources</h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li class="pb-3"><a href="https://hope-action.com/" target="_blank">Hope-Action Group</a></li>
        <li class="pb-3"><a href="https://ceric.ca/about/" target="_blank">CERIC: Advancing Career Development in Canada</a></li>
        <li class="pb-3"><a href="https://ppc.sas.upenn.edu/learn-more/perma-theory-well-being-and-perma-workshops" target="_blank">University of Pennsylvania PERMA Theory of Wellbeing workshops</a></li>
        <li class="pb-3"><a href="https://www.waceinc.org/papers/How%20To%20Make%20The%20Most%20of%20Work%20Integrated%20Learning.pdf" target="_blank">How to Make the Most of Work-Integrated Learning: A Guide for Students, Lecturers & Supervisors</a></li>
        <li class="pb-3"><a href="https://www.ccohs.ca/oshanswers/psychosocial/stress_workplace.html" target="_blank">Mental Health - Dealing with Stress in the Workplace</a>(Canadian Centre for Occupational Health and Safety)</li>
        <li class="pb-3"><a href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/work-life-balance-quiz/#balance-quiz" target="_blank">Work-Life Balance Quiz </a></li>
        <li class="pb-3"><a href="https://www.workplacewellnessonline.ca/resources/work-life-balance.php" target="_blank">Resources to Help Maintain Work-Life Balance </a></li>
        <li class="pb-3"><a href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/whats-your-stress-index/" target="_blank">What's Your Stress Index?</a> (quiz) </li>
        <li class="pb-3"><a href="https://www.ccohs.ca/oshanswers/psychosocial/stress.html" target="_blank">Q&A about workplace stress</a></li>
        <li class="pb-3"><a href="https://www.ccohs.ca/oshanswers/psychosocial/" target="_blank">Q&A about health promotion/wellness/psychosocial </a></li>
        <li class=""><a href="https://www.dal.ca/campus_life/career-and-leadership/job-resources-services/Resources/career-resources-for-diverse-groups.html" target="_blank">Career resources for diverse groups</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '07',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
